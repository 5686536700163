<template>
  <!-- 
    画面: 宿設定
    用途: サイト管理者が宿について設定する
   -->
  <div>
    <CRow>
      <CCol
        md="12"
        lg="12"
      >

        <CCard>
          <CCardHeader>
            <strong class="card-title">
              {{ $t("facilitySettingPage.pageTitle") }}
            </strong>
          </CCardHeader>
          <CCardBody>
            <CForm class="submit_form ">
              <div class="setting-from-edit">

                <!-- #region ========== 宿管理 ========== -->
                <h4 class="form-title">
                  {{ $t("facilitySettingPage.facilityCardTitle") }}
                </h4>
                <!-- #region 宿詳細リンク -->
                <CRow>
                  <CCol class="label"><strong>{{ $t("facilitySettingPage.facilityPublicSetting") }}</strong></CCol>
                  <CCol class="i-field">
                    <CSwitch
                      class="mt-1 swc sw-2"
                      color="info"
                      variant="opposite"
                      :labelOn='$t("common.public")'
                      :labelOff='$t("common.private")'
                      :checked.sync="searchable"
                    />
                    <ul>
                      <li> ※「{{$t("common.public")}}」の場合、記事や宿検索結果一覧に宿詳細ページへのリンクが表示されます。 </li>
                    </ul>
                  </CCol>
                </CRow>
                <!-- #endregion 宿詳細リンク -->
                <!-- #endregion ========== 宿管理 ========== -->

                <br>

                <template v-if="canPlanManagementVersion">
                  <!-- #region ========== プラン管理 ========== -->
                  <h4 class="form-title">
                    {{ $t("facilitySettingPage.planCardTitle") }}
                  </h4>
                  <!-- #region 予約機能 -->
                  <CRow>
                    <CCol class="label"><strong>{{ $t("facilitySettingPage.reservationFeature") }}</strong></CCol>
                    <CCol class="i-field">
                      <CSwitch
                        class="mt-1 swc sw-2"
                        color="info"
                        variant="opposite"
                        :labelOn='$t("common.valid")'
                        :labelOff='$t("common.invalid")'
                        :checked="edit.planFeature"
                      />
                      <ul>
                        <li>※有効にした場合、宿の管理メニューの「プラン管理」メニューが表示され、プラン登録が可能になります。</li>
                        <li>
                          ※無効にした場合、宿の管理メニューの「プラン管理」メニューが非表示になります。<br>
                          また、宿の全プランが非公開になります。<br>
                          尚、予約済みの情報は破棄せず、宿オペレーターや一般ユーザーによる予約履歴等の参照が可能です。
                        </li>
                      </ul>
                    </CCol>
                  </CRow>
                  <!-- #endregion 予約機能 -->
                  <!-- #region 決済方法 -->
                  <CRow>
                    <CCol class="label"><strong>{{ $t("facilitySettingPage.clearing") }}</strong></CCol>
                    <CCol class="i-field">
                      <CInputCheckbox
                        class="mx-1"
                        v-for="(option, n) in clearingList"
                        :key="n + option"
                        :label="option.name"
                        :value="option.id"
                        :checked.sync="option.enabled"
                        custom
                        :disabled="option.id === 0 ? 'disabled' : undefined"
                      />
                    </CCol>
                  </CRow>
                  <!-- #endregion 決済方法 -->
                  <!-- #region 掲載サイト -->
                  <CRow>
                    <CCol class="label"><strong>{{ $t("facilitySettingPage.site") }}</strong></CCol>
                    <CCol class="i-field">
                      <CInputCheckbox
                        class="mx-1"
                        v-for="(option, n) in allSite"
                        :key="n + option"
                        :label="option.name"
                        :value="option.id"
                        :checked.sync="option.enabled"
                        custom
                      />
                      <ul>
                        <li>※有効な掲載サイトのみ、宿のプラン料金を設定できます。</li>
                        <li>※プラン料金設定済みの掲載サイトを無効にした場合、その掲載サイトの全プランが非公開になります。<br>
                          尚、予約済みの情報は破棄せず、宿オペレーターや一般ユーザーによる予約履歴等の参照が可能です。
                        </li>
                      </ul>
                    </CCol>
                  </CRow>
                  <!-- #endregion 掲載サイト -->
                  <!-- #endregion ========== プラン管理 ========== -->
                </template>

                <!-- #region 更新ボタン -->
                <CButton
                  class="px-5 mt-4"
                  color="success"
                  @click="onUpdateClicked()"
                >
                  <i class="cil-save"></i> {{ $t("common.update") }}
                </CButton>
                <!-- #endregion 更新ボタン -->
              </div>

            </CForm>
          </CCardBody>
        </CCard>

      </CCol>
    </CRow>

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import STATUS from "@/mixins/property";
import HOTEL_STATUS from "@/mixins/property";
import config from '@/config'

export default {
  name: "FacilitySetting",

  mixins: [STATUS, HOTEL_STATUS],

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      searchable: false,
      //#endregion Flag


      //#region Edit
      edit: {
        facilityDetailPage: false,
        planFeature: false,
      },
      //#endregion Edit


      //#region Options
      clearingList: [
        { id: 0, name: "現地決済", enabled: true },
        { id: 1, name: "オンライン決済", enabled: false },
      ],

      selectedSiteList: [],
      allSite: [],
      siteIdList: [],

      //#endregion Options


      //#region Response data
      response: [],
      //#endregion Response data


      //#region Message
      errorMessage: "",
      //#endregion Message
    };
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    uid() {
      return this.$store.state.uniqueId;
    },

    canPlanManagementVersion() {
      return config.VERSION >= 2;
    },
  },

  methods: {
    //#region Event
    onUpdateClicked() {
      this.reqPut();
      // this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },
    //#endregion Event


    //#region Request
    reqGet() {
      this.loading = true;

      const callback = (data) => {
        this.edit = data;
        this.searchable = this.edit.status == this.HOTEL_STATUS.SEARCHABLE;

        this.reqGetAllSite();
        this.loading = false;
      };

      const errorCallback = () => {
        this.loading = false;
      };

      this.reqGetHotel(callback, errorCallback);
    },

    /**登録済みサイト取得 */
    reqGetAllSite() {

      const callback = (data) => {
        this.allSite = data.sites
          .filter(a => a.status === this.STATUS.VALID)
          .map(a => {
            return { id: a.id, name: a.name, enabled: false }
          });
        this.debugLog(this.allSite);
        this.updateOrderIdAscList(this.allSite);
        this.reqGetFacilitySiteList();
        this.loading = false;
      };

      const errorCallback = () => {
        this.loading = false;
      };

      this.reqGetSiteAll(callback, errorCallback);
    },

    /**宿と関連づいたサイト取得 */
    reqGetFacilitySiteList() {

      const callback = (data) => {
        this.siteIdList = data.list.map(a => a.siteId);

        this.allSite
          .filter(a => this.siteIdList.includes(a.id))
          .forEach(a => a.enabled = true);

        this.loading = false;
      };

      const errorCallback = () => {
        this.loading = false;
      };

      this.reqGetHotelSiteAll(callback, errorCallback);
    },

    reqPut() {
      this.reqPutFacilityStatus();
      this.reqPutFacilityPlanFeatureStatus();
      this.reqPostFacilitySiteList();
    },

    /** 
     * 宿の公開ステータス更新
     * OFF: 宿検索結果一覧に非表示。記事の宿詳細リンク非表示。
     */
    reqPutFacilityStatus() {
      // TODO: 宿公開ステータス更新
      this.loading = true;

      const status = this.searchable
        ? (this.edit.status | this.HOTEL_STATUS.FLAG_CONTENT_VISIBLE | this.HOTEL_STATUS.FLAG_SEARCHABLE)
        : (this.edit.status & ~this.HOTEL_STATUS.FLAG_CONTENT_VISIBLE & ~this.HOTEL_STATUS.FLAG_SEARCHABLE);

      let body = { status: status };

      const callback = () => {
        this.successModal = true; // TODO:
        this.loading = false;
      };

      const errorCallback = () => {
        this.loading = false;
      };

      this.reqPutHotel(body, callback, errorCallback);
    },

    /** 宿のプラン予約機能有効無効ステータス更新 */
    reqPutFacilityPlanFeatureStatus() {

    },

    /** 宿の掲載サイト有効無効ステータス更新 */
    reqPutFacilitySiteStatus() {
      this.loading = true;

      const body = this.allSite.filter((a) => a.enabled);

      const callback = () => {
        this.loading = false;
        this.successModal = true;
      };

      const errorCallback = () => {
        this.loading = false;
        this.errorModal = true;
      };

      this.reqPutHotelSite(body, callback, errorCallback);
    },

    /**宿xサイト relation update */
    reqPostFacilitySiteList() {

      const body = this.allSite
        .filter((a) => a.enabled)
        .map((a) => a.id);

      const callback = () => {
        this.successModal = true;
        this.reqGetAllSite();
      };

      const errorCallback = () => {
        this.loading = false;
      };

      this.reqPostHotelSite(body, callback, errorCallback);
    },
    //#endregion Request


    //#region Method
    updateOrderIdAscList(datas) {
      datas.sort((a, b) => a.id - b.id);
      console.log(datas)
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>

<style lang="scss" scoped>
.setting-from-edit {
  & > .row {
    margin-bottom: 0.5rem;
    .label {
      strong {
        height: 100%;
      }
    }
    .i-field {
      background: #f7f9fc;
      border: 1px solid #cfd5df;
      border-radius: 5px;
      padding-top: 2px;
      padding-bottom: 4px;
      margin-right: 15px;
    }
  }
}
</style>